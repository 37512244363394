import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import FollowUps from 'components/assistant/workflows/components/follow-ups'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowFollowUpsThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FOLLOW_UPS
> = ({ blockParams, outputData, blockStatus }) => {
  const { relatedQuestions } = blockParams
  const isCompleted = !!outputData
  const [setActiveRelatedQuestion] = useAssistantWorkflowStore(
    useShallow((state) => [state.setActiveRelatedQuestion])
  )

  if (!relatedQuestions || relatedQuestions.length === 0) return null

  return (
    <AssistantWorkflowThreadBlock>
      <FollowUps
        followUps={relatedQuestions}
        onSelectQuestion={setActiveRelatedQuestion}
      />
      {isCompleted && (
        <AssistantWorkflowYouComponent>
          <AssistantWorkflowThreadText
            blockStatus={blockStatus}
            text={outputData.value.length > 0 ? outputData.value : 'N/A'}
          />
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowFollowUpsInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FOLLOW_UPS
> = ({ blockParams, onCompleted, outputData }) => {
  const { placeholder, optional } = blockParams
  const [text, setText] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const isCompleted = !!outputData
  const [activeRelatedQuestion, setActiveRelatedQuestion] =
    useAssistantWorkflowStore(
      useShallow((state) => [
        state.activeRelatedQuestion,
        state.setActiveRelatedQuestion,
      ])
    )

  React.useEffect(() => {
    setText(activeRelatedQuestion)
  }, [activeRelatedQuestion])

  const onSubmit = () => {
    if (!text && !optional) return
    setIsLoading(true)
    onCompleted({ value: text })
    setIsLoading(false)
    setActiveRelatedQuestion('')
    setText('')
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onSubmit()
    }
  }

  return (
    <WorkflowInput>
      <div className="relative flex w-full items-center">
        <Input
          type="text"
          className="min-h-16 w-full resize-none pr-20 focus-visible:ring-offset-0"
          placeholder={placeholder}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button
          className="absolute right-4"
          onClick={onSubmit}
          disabled={isCompleted || isLoading || (!optional && !text)}
          isLoading={isCompleted || isLoading}
        >
          {optional && !text ? 'Skip' : 'Send'}
        </Button>
      </div>
    </WorkflowInput>
  )
}
