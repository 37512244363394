import * as React from 'react'

import { RelatedQuestion } from 'openapi/models/RelatedQuestion'

import { Button } from 'components/ui/button'

interface FollowUpsProps {
  followUps: RelatedQuestion[]
  onSelectQuestion: (text: string) => void
}

const FollowUps: React.FC<FollowUpsProps> = ({
  followUps,
  onSelectQuestion,
}) => {
  return (
    <div className="flex w-full flex-col items-start px-4 py-6">
      <p className="flex h-12 items-center px-2 font-medium">Follow-ups</p>
      {followUps.map((followUp) => (
        <Button
          key={followUp.text}
          onClick={() => onSelectQuestion(followUp.text)}
          variant="unstyled"
          className="group relative flex h-10 w-full items-center justify-start rounded-sm px-2 transition hover:bg-button-secondary"
        >
          <p className="text-xs">{followUp.text}</p>
          <hr className="absolute inset-x-2 bottom-0 group-last:hidden" />
        </Button>
      ))}
    </div>
  )
}

export default FollowUps
