import * as React from 'react'

import { Coins } from 'lucide-react'
import { NotebookText } from 'lucide-react'

import {
  DatabaseSource,
  KnowledgeSourceConfig,
} from 'components/assistant/utils/assistant-knowledge-sources'

export const NEW_KNOWLEDGE_SOURCE_CONFIG: Record<
  DatabaseSource,
  {
    icon: React.ReactElement
    title: string
    description: string
  }
> = {
  [DatabaseSource.CUATRECASAS]: {
    icon: <img src="/img/logos/cuatrecasas.png" alt="Cuatrecasas" />,
    title: KnowledgeSourceConfig[DatabaseSource.CUATRECASAS].name,
    description: 'Cuatrecasas knowledge base',
  },
  [DatabaseSource.EDGAR]: {
    icon: <img src="/img/flags/edgar.png" alt="EDGAR" />,
    title: KnowledgeSourceConfig[DatabaseSource.EDGAR].name,
    description:
      KnowledgeSourceConfig[DatabaseSource.EDGAR].tipsText?.split('.')[0] || '',
  },
  [DatabaseSource.EURLEX]: {
    icon: <img src="/img/flags/eur-lex.png" alt="EUR-Lex" />,
    title: KnowledgeSourceConfig[DatabaseSource.EURLEX].name,
    description: KnowledgeSourceConfig[DatabaseSource.EURLEX].tipsText || '',
  },
  [DatabaseSource.FRENCH_CASELAW]: {
    icon: <img src="/img/flags/france.png" alt="French Case Law" />,
    title: KnowledgeSourceConfig[DatabaseSource.FRENCH_CASELAW].name,
    description:
      KnowledgeSourceConfig[DatabaseSource.FRENCH_CASELAW].tipsText || '',
  },
  [DatabaseSource.MEMOS]: {
    icon: <NotebookText />,
    title: KnowledgeSourceConfig[DatabaseSource.MEMOS].name,
    description: KnowledgeSourceConfig[DatabaseSource.MEMOS].tipsText || '',
  },
  [DatabaseSource.TAX]: {
    icon: <Coins />,
    title: KnowledgeSourceConfig[DatabaseSource.TAX].name,
    description: KnowledgeSourceConfig[DatabaseSource.TAX].tipsText || '',
  },
  [DatabaseSource.US_CASELAW]: {
    icon: <img src="/img/flags/us.png" alt="US Case Law" />,
    title: KnowledgeSourceConfig[DatabaseSource.US_CASELAW].name,
    description:
      KnowledgeSourceConfig[DatabaseSource.US_CASELAW].tipsText || '',
  },
  [DatabaseSource.SWEDEN_CASELAW]: {
    icon: <img src="/img/flags/sweden.png" alt="Swedish Case Law" />,
    title: KnowledgeSourceConfig[DatabaseSource.SWEDEN_CASELAW].name,
    description:
      'Case law from Swedish civil, administrative, and constitutional courts',
  },
  [DatabaseSource.SINGAPORE_CASELAW]: {
    icon: <img src="/img/flags/singapore.png" alt="Singapore Law" />,
    title: KnowledgeSourceConfig[DatabaseSource.SINGAPORE_CASELAW].name,
    description:
      'Case law from Singapore civil, administrative, and constitutional courts',
  },
  [DatabaseSource.MACFARLANES]: {
    icon: <img src="/img/logos/macfarlanes.png" alt="Macfarlanes" />,
    title: KnowledgeSourceConfig[DatabaseSource.MACFARLANES].name,
    description: 'Macfarlanes knowledge base',
  },
  [DatabaseSource.LEFEBVRE]: {
    icon: <img src="/img/flags/france.png" alt="French Case Law" />,
    title: KnowledgeSourceConfig[DatabaseSource.LEFEBVRE].name,
    description: KnowledgeSourceConfig[DatabaseSource.LEFEBVRE].tipsText || '',
  },
  [DatabaseSource.AUSBREACHREPORTING]: {
    icon: <img src="/img/flags/au.png" alt="Australia Breach Reporting" />,
    title: KnowledgeSourceConfig[DatabaseSource.AUSBREACHREPORTING].name,
    description:
      'Case law from Australian civil, administrative, and constitutional courts',
  },
}
