import _ from 'lodash'

import { ProductLabel } from 'openapi/models/ProductLabel'
import { TimeInterval } from 'openapi/models/TimeInterval'
import Services from 'services'
import { TaskType } from 'types/task'

export interface UsageDataTimeseriesEntryRaw {
  timestamp: string
  queryCountByType: {
    [key in ProductLabel]: number
  }
  userCountByType: {
    [key in ProductLabel]: number
  }
  queryCountTotal: number
  userCountTotal: number
}

export interface UsageDataRaw {
  distinctTypes: TaskType[]
  aggregate: {
    userCountTotal: number // active users
    queryCountTotal: number
    userCountByType: {
      [key in ProductLabel]: number
    }
    queryCountByType: {
      [key in ProductLabel]: number
    }
  }
  // should be returned in ascending order from BE
  timeseries: {
    [key in TimeInterval]: UsageDataTimeseriesEntryRaw[]
  }
  userStats: {
    // user email as key
    [key: string]: {
      // 0 value means user has access to product type but ran zero queries
      // no entry for product type means user doesn't have access to it
      [key in ProductLabel]: number
    }
  }
}

// interface for post-processed FE data
export interface UsageDataTimeseriesEntry {
  timestamp: string
  parsedDateShortened: string // e.g. Jan 1
  parsedDateString: string // e.g. January 1, 2024
  queryCountByType: {
    [key: string]: number
  }
  userCountByType: {
    [key: string]: number
  }
  queryCountTotal: number
  userCountTotal: number
}

// interface for post-processed FE data
export interface UsageData {
  // product label strings are transformed for display for ks labels
  // e.g. Assist: Vault -> Assist (Vault)
  distinctTypes: string[]
  aggregate: {
    // for area chart
    userCountTotal: number
    queryCountTotal: number
    // for pie chart & area chart
    userCountByType: {
      [key: string]: number
    }
    queryCountByType: {
      [key: string]: number
    }
  }
  // for area chart
  timeseriesMap: {
    [key in TimeInterval]: UsageDataTimeseriesEntry[]
  }
  // for user stats table
  userStats: {
    // dictionary is now flattened with userEmail and product lines as keys
    userEmail: string
    // 0 value means user has access but ran zero queries
    // no entry for product type means user doesn't have access to it
    [key: string]: number | string
  }[]
}

export interface FetchUsageDataProps {
  workspaceSlug: string
  createdAfterOrEqual: Date
  createdBeforeOrEqual: Date
  userTz?: string
  selectedProductTypes?: string[] // ProductLabel enum values
}

export async function FetchUsageDataRaw(
  props: FetchUsageDataProps,
  abortController: AbortController
): Promise<UsageDataRaw> {
  const result = await Services.Backend.Post<UsageDataRaw>(
    `dashboard/usage`,
    {
      workspaceSlug: props.workspaceSlug,
      createdAfterOrEqual: props.createdAfterOrEqual.toISOString(),
      createdBeforeOrEqual: props.createdBeforeOrEqual.toISOString(),
      userTz: props.userTz ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      productTypes: props.selectedProductTypes,
      includeMonthData: true,
    },
    {
      throwOnError: true,
      signal: abortController.signal,
    }
  )
  return result
}

interface UsageMetadata {
  distinctTypes: string[] // ProductLabel enum values
}

export async function FetchUsageMetadata(
  workspaceSlug: string
): Promise<UsageMetadata> {
  const params = new URLSearchParams()
  params.append('workspace_slug', workspaceSlug)

  const result = await Services.Backend.Get<UsageMetadata>(
    `dashboard/usage/metadata?${params.toString()}`,
    {
      throwOnError: true,
    }
  )
  return result
}

// for knowledge source shim
const PRODUCT_LABEL_STRING_WITH_KS_TO_DISPLAY_STRING: {
  [key: string]: string
} = {
  [ProductLabel.ASSIST_VAULT]: 'Assist (Vault)',
  [ProductLabel.ASSIST_NO_UPLOADED_FILES]: 'Assist (No Uploaded Files)',
  [ProductLabel.ASSIST_USER_UPLOADED_FILES]: 'Assist (User Uploaded Files)',
  [ProductLabel.ASSIST_EDGAR]: 'Assist (EDGAR)',
  [ProductLabel.ASSIST_FRENCH_CASE_LAW]: 'Assist (French Case Law)',
  [ProductLabel.ASSIST_SWEDEN_CASE_LAW]: 'Assist (Sweden Case Law)',
  [ProductLabel.ASSIST_US_CASE_LAW]: 'Assist (US Case Law)',
  [ProductLabel.ASSIST_SINGAPORE_CASE_LAW]: 'Assist (Singapore Case Law)',
  [ProductLabel.ASSIST_EUR_LEX]: 'Assist (EUR-Lex)',
  [ProductLabel.ASSIST_MEMOS]: 'Assist (Memos)',
  [ProductLabel.ASSIST_TAX]: 'Assist (Tax)',
  [ProductLabel.ASSIST_CUATRECASAS]: 'Assist (Cuatrecasas)',
  [ProductLabel.ASSIST_MACFARLANES]: 'Assist (Macfarlanes)',
  [ProductLabel.ASSIST_LEFEBVRE]: 'Assist (Spanish Legal)',
  [ProductLabel.ASSIST_AUSTRALIA_BREACH_REPORTING]:
    'Assist (Australia Breach Reporting)',
  [ProductLabel.DRAFT_NO_UPLOADED_FILES]: 'Draft (No Uploaded Files)',
  [ProductLabel.DRAFT_USER_UPLOADED_FILES]: 'Draft (User Uploaded Files)',
  [ProductLabel.DRAFT_VAULT]: 'Draft (Vault)',
}

// for knowledge source shim
const PRODUCT_LABEL_STRING_WITH_KS_TO_DROPDOWN_DISPLAY_STRING: {
  [key: string]: string
} = {
  [ProductLabel.ASSIST_VAULT]: 'Vault',
  [ProductLabel.ASSIST_NO_UPLOADED_FILES]: 'No Uploaded Files',
  [ProductLabel.ASSIST_USER_UPLOADED_FILES]: 'User Uploaded Files',
  [ProductLabel.ASSIST_EDGAR]: 'EDGAR',
  [ProductLabel.ASSIST_FRENCH_CASE_LAW]: 'French Case Law',
  [ProductLabel.ASSIST_SWEDEN_CASE_LAW]: 'Sweden Case Law',
  [ProductLabel.ASSIST_US_CASE_LAW]: 'US Case Law',
  [ProductLabel.ASSIST_SINGAPORE_CASE_LAW]: 'Singapore Case Law',
  [ProductLabel.ASSIST_EUR_LEX]: 'EUR-Lex',
  [ProductLabel.ASSIST_MEMOS]: 'Memos',
  [ProductLabel.ASSIST_TAX]: 'Tax',
  [ProductLabel.ASSIST_CUATRECASAS]: 'Cuatrecasas',
  [ProductLabel.ASSIST_MACFARLANES]: 'Macfarlanes',
  [ProductLabel.ASSIST_LEFEBVRE]: 'Spanish Legal',
  [ProductLabel.ASSIST_AUSTRALIA_BREACH_REPORTING]:
    'Australia Breach Reporting',
  [ProductLabel.DRAFT_NO_UPLOADED_FILES]: 'No Uploaded Files',
  [ProductLabel.DRAFT_USER_UPLOADED_FILES]: 'User Uploaded Files',
  [ProductLabel.DRAFT_VAULT]: 'Vault',
}

export const getDisplayStringForProductLabel = (input: string): string => {
  // only change the string if it's in the mapping
  const result = PRODUCT_LABEL_STRING_WITH_KS_TO_DISPLAY_STRING[input]
  if (_.isNil(result)) {
    console.warn(`No display string found for product label: ${input}`)
    return input
  }
  return result
}

export const getDropdownDisplayStringForProductLabel = (
  input: string
): string => {
  const result = PRODUCT_LABEL_STRING_WITH_KS_TO_DROPDOWN_DISPLAY_STRING[input]
  if (_.isNil(result)) {
    console.warn(`No dropdown display string found for product label: ${input}`)
    return input
  }
  return result
}
