import * as React from 'react'
import { Control, Controller } from 'react-hook-form'

import { LanguageCode } from 'openapi/models/LanguageCode'

import { getLanguageText } from 'utils/user-profile-helpers'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

interface ProfileLanguageFormProps {
  control: Control<any>
}

const LANGUAGE_OPTIONS = Object.values(LanguageCode).map((code) => ({
  label: getLanguageText(code),
  value: code,
}))

const SPECIAL_OPTIONS = LANGUAGE_OPTIONS.filter(
  (code) => code.label.startsWith('English') || code.label.startsWith('Spanish')
).sort((a, b) => a.label.localeCompare(b.label))

const OTHER_OPTIONS = LANGUAGE_OPTIONS.filter(
  (code) => !SPECIAL_OPTIONS.includes(code)
).sort((a, b) => a.label.localeCompare(b.label))

const OPTIONS = [...SPECIAL_OPTIONS, ...OTHER_OPTIONS]

export const FORM_HEADING = 'What is your preferred language?'
export const FORM_DESCRIPTION =
  'Harvey replies in the language of your prompt but will use a specific dialect (e.g., American English, British English) if specified.'

const ProfileLanguageForm: React.FC<ProfileLanguageFormProps> = ({
  control,
}) => {
  return (
    <Controller
      name="preferredLanguage"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <Select value={field.value} onValueChange={field.onChange}>
          <SelectTrigger className="w-full max-w-64">
            <SelectValue placeholder="Select a language" />
          </SelectTrigger>
          <SelectContent>
            {OPTIONS.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
    />
  )
}

export default ProfileLanguageForm
