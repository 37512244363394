/* tslint:disable */
/* eslint-disable */
/**
 * Knowledge Sources
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const KnowledgeSourceType = {
    FILES: 'files',
    VAULT: 'vault',
    CUATRECASAS: 'cuatrecasas',
    EDGAR: 'edgar',
    EURLEX: 'eurlex',
    FRENCH_CASELAW: 'french_caselaw',
    MEMO: 'memo',
    TAX: 'tax',
    US_CASELAW: 'us_caselaw',
    SWEDEN_CASELAW: 'sweden_caselaw',
    SINGAPORE_CASELAW: 'singapore_caselaw',
    MACFARLANES: 'macfarlanes',
    WEB: 'web',
    AUS_BREACH: 'aus_breach',
    PDF_DOCUMENT: 'pdf_document',
    LEFEBVRE: 'lefebvre'
} as const;
export type KnowledgeSourceType = typeof KnowledgeSourceType[keyof typeof KnowledgeSourceType];


export function instanceOfKnowledgeSourceType(value: any): boolean {
    for (const key in KnowledgeSourceType) {
        if (Object.prototype.hasOwnProperty.call(KnowledgeSourceType, key)) {
            if (KnowledgeSourceType[key as keyof typeof KnowledgeSourceType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function KnowledgeSourceTypeFromJSON(json: any): KnowledgeSourceType {
    return KnowledgeSourceTypeFromJSONTyped(json, false);
}

export function KnowledgeSourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KnowledgeSourceType {
    return json as KnowledgeSourceType;
}

export function KnowledgeSourceTypeToJSON(value?: KnowledgeSourceType | null): any {
    return value as any;
}

export function KnowledgeSourceTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): KnowledgeSourceType {
    return value as KnowledgeSourceType;
}

